import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Apiurl from "../Apiurl";

export default function Studentoptions() {
  const [StudentData, setStudentData] = useState([]);
  const apiurl = Apiurl();

  async function fetchStudentData() {
    try {
      const batch = window.localStorage.getItem("academicyear");

      const response = await axios.get(
        apiurl + `studentreg/?student_id=&batch=${batch}`
      );
      console.log(response.data);
      setStudentData(response.data);
      // Handle the response data here
    } catch (error) {
      console.error(error);
      // Handle error here
      console.log("network error");
    }
  }
  const options = StudentData.map((student) => ({
    value: student.student_id,
    label: student.std_name + " - " + student.student_id,
  })).reverse();

  useEffect(() => {
    fetchStudentData();
  }, []);

  return options;
}
