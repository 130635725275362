import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import Courseoptions from "../../Options/Courseoptions";
import Form from "react-bootstrap/Form";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Employeeattendance() {
  const [jsonList, setJsonList] = useState([]);

  const initialData = {
    name: "Employee Attendance",
    link: "/employee_attendance",
    active: "no",
  };
  const apiurl = Apiurl();
  const navigate = useNavigate();
  const options = Courseoptions();
  const { user } = useParams();
  const [sid, setsid] = useState("");

  const [loader, setloader] = useState(false);
  const [sname, setsname] = useState(null);
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [fetchingdata, setfetchingdata] = useState(null);
  const [reason, setreason] = useState("null");
  const [Studentdata, setStudentdata] = useState([]);
  const [attendancedata, setattendancedata] = useState([]);

  const [show, setShow] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  const [showDetail, setShowDetail] = useState(true);

  const [mdate, setmdate] = useState("");
  const [checkin_time, setcheckin_time] = useState("");
  const [checkout_time, setcheckout_time] = useState("");
  const [checkin_lattitude, setcheckin_lattitude] = useState("");
  const [checkout_lattitude, setcheckout_lattitude] = useState("");
  const [checkin_longitude, setcheckin_longitude] = useState("");
  const [checkout_longitude, setcheckout_longitude] = useState("");
  const [m_mode, setm_mode] = useState("");
  const [m_reason, setm_reason] = useState("");
  const [m_id, setm_id] = useState(0);

  const handleCheckoutClick = (date, checkin_time, m_id) => {
    setmdate(date);
    setcheckin_time(checkin_time);
    setm_id(m_id);
    setShowModal(true);
  };
  const handleCloseCheckoutModal = () => {
    setShowModal(false);
  };

  const handleViewClick = (date, checkin_time, mode, checkout_time) => {
    setmdate(date);
    setcheckin_time(checkin_time);
    setcheckout_time(checkout_time);
    setm_mode(mode);
    setShowViewModal(true);
  };
  const handleCloseViewModal = () => {
    setShowViewModal(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const updateDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    const time = today.toLocaleTimeString();

    setdate(formattedDate);
    settime(time);
    // new Date().toLocaleTimeString();
    console.warn(time);
  };

  async function GetStudData() {
    try {
      console.log(sname);
      const response = await axios.get(apiurl + `staffdetails/`);
      const responsen2 = await axios.get(
        apiurl +
          `staffattendance/?date=${date}&student_id=&checkin_time=&checkout_time=`
      );
      const convertedarray = response.data.map((item, index) => ({
        staff_id: item.Staffid,
        staff_name: item.Name,
        date: date,
        checkin_time: time,
        checkout_time: null,
        checkin_lattitude: "Manual",
        checkout_lattitude: "Manual",
        checkin_longitude: "Manual",
        checkout_longitude: "Manual",
        mode: "Manual",
        reason: reason,
        isabsent: false,
      }));
      const studentIds1 = convertedarray.map((item) => item.staff_id);
      const studentIds2 = responsen2.data.map((item) => item.staff_id);
      const commonStudentIds = studentIds1.filter((id) =>
        studentIds2.includes(id)
      );
      console.log(convertedarray);
      console.log(commonStudentIds);
      const filteredResponse1 = convertedarray.filter(
        (item) => !commonStudentIds.includes(item.staffid)
      );
      // const filteredResponse2 = responsen2.data.filter(item => !commonStudentIds.includes(item.student_id));
      console.log(filteredResponse1);
      // setsid(response.data.student_id);
      setStudentdata(filteredResponse1);
      setShowDetail(false);
    } catch (error) {
      console.error(error);
    }
  }
  const handleStudentChange = (selectedOption) => {
    setsname(selectedOption);
  };
  const formatedate = (inputDate) => {
    // Ensure inputDate is a valid Date object
    const date = new Date(inputDate);

    // Extract day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

    // Combine components into "ddmmyy" format
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const handleSubmit = async () => {
    if (
      window.confirm(
        `Do you want to Update the attendance on ${formatedate(date)} ?`
      )
    ) {
      const formdata = Studentdata.filter((item) => item.isabsent) // Filter out items where ispresent is true
        .map((item, index) => ({
          staff_id: item.staff_id,
          course: item.course,
          staff_name: item.staff_name,
          date: date,
          checkin_time: time,
          checkout_time: null,
          checkin_lattitude: "Manual",
          checkout_lattitude: "Manual",
          checkin_longitude: "Manual",
          checkout_longitude: "Manual",
          mode: "Manual",
          reason: null,
        }));
      console.warn(formdata);
      try {
        const response = await axios.post(
          apiurl + "bulkattendancestaff/",
          formdata,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
        alert("Updated!");
      } catch (error) {
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
        alert("Some Error occured\nTry again Later");
        setloader(false);
      }
    }
  };

  useEffect(() => {
    updateDate();
    GetStudData();
  }, []);
  const handleFormChange = (index, event) => {
    let data = [...Studentdata];
    data[index][event.target.name] = event.target.checked;
    setStudentdata(data);
    console.log(data);
  };
  // useEffect to add initial data to the JSON list
  useEffect(() => {
   Tabhelper(initialData,setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Employee Attendance"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>EMPLOYEE ATTENDANCE</b>
          </div>
          {showDetail && <>Loading...</>}
          {Studentdata != "" ? (
            <div>
              <div>
                <div>
                  <div style={{ padding: "1%" }}>
                    <div className="table-responsive">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Sno</th>
                            <th scope="col"> Name </th>

                            <th scope="col"> Student Id</th>
                            <th scope="col"> Mode</th>
                            <th scope="col">Present </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Studentdata.map((item, index) => (
                            <>
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{item.staff_name}</td>
                                <td>{item.staff_id}</td>
                                <td>Manual</td>
                                <td>
                                  {/* <input
                                      name="isabsent"
                                      type="checkbox"
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    /> */}
                                  <Form.Check // prettier-ignore
                                    type="switch"
                                    id="mySwitch"
                                    onChange={(event) =>
                                      handleFormChange(index, event)
                                    }
                                    name="isabsent"
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <Button onClick={handleSubmit} variant="primary">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Maincomponent>
  );
}
