

export const Tabhelper = (initialData, setJsonList) => {
    const storedList = localStorage.getItem("jsonList");
    console.warn("This msg is from tabhelper" + storedList);
    if (storedList) {
      console.warn(storedList);
      const parsedList = JSON.parse(storedList);
      setJsonList(parsedList);
  
      // Check if the initialData is not already in the list before adding it
      if (!parsedList.some((item) => item.name === initialData.name)) {
        const updatedList = [...parsedList, initialData];
        setJsonList(updatedList);
        window.localStorage.setItem("jsonList", JSON.stringify(updatedList));
      }
    }
  };
  