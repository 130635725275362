import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Basedata_creation() {
  const [jsonList, setJsonList] = useState([]);
  //
  const apiurl = Apiurl();
  const { user } = useParams();
  const navigate = useNavigate();
  const [nameofpayer, setnameofpayer] = useState("");
  const [sname, setsname] = useState(null);
  const [date, setdate] = useState("");
  const [amount, setamount] = useState("");
  const [billno, setbillno] = useState("");
  const [remarks, setremarks] = useState("None");
  const [mode, setmode] = useState(null);
  const [expensetypeval, setexpensetypeval] = useState(null);
  const [svalue, setsvalue] = useState(0);
  const [incomedata, setincomedata] = useState([]);
  const [Studentbillingdata, setStudentbillingdata] = useState([]);
  const [totalcount, settotalcount] = useState(0);
  const modeoptions = [
    { value: "Cash", label: "Cash" },
    { value: "Cheque", label: "Cheque" },
    { value: "Online", label: "Online" },
    { value: "Gpay/Phonepay/Paytm", label: "Gpay/Phonepay/Paytm" },
  ];

  const expensetype = [
    { value: "Intrest on Loan", label: "Intrest on Loan" },
    { value: "Salary/Wages", label: "Salary/Wages" },
    { value: "Rent/EB", label: "Rent/EB" },
    { value: "Printing & Stationary", label: "Printing & Stationary" },
    { value: "Advertisement", label: "Advertisement" },
    { value: "Refreshments", label: "Refreshments" },
    { value: "Online", label: "Misc.Expense" },
  ];
  let dollarIndianLocale = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
  });
  const handleCancelBill = async (billid) => {
    if (window.confirm("Are you sure you want to Cancel this Bill?")) {
      try {
        console.log(billid);
        const response = await axios.patch(apiurl + `expense/${billid}/`, {
          status: "Cancelled",
        });

        alert("Bill Cancelled");
        GetBillNoData();
        GetIncomeData();
        setnameofpayer("");
        setamount("");
        setmode(null);
        setremarks("");
      } catch (error) {
        console.error(error);
        alert("Some Error occured\nTry again Later");
      }
    }
  };

  const updateDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setdate(formattedDate);
  };

  const handleSubmit = async () => {
    const formdata = {
      bill_no: billno,
      date: date,
      amount: amount,
      status: "Approved",
      mode: mode.value,
      remarks: remarks,
      billedby: user,
      nameofpayer: nameofpayer,
      billtype: "Expense",
      expensetype: expensetypeval.value,
    };
    // console.log(formdata);
    try {
      const response = await axios.post(apiurl + "expense/", formdata);
      alert("Expense Bill added");
      GetIncomeData();
      const jsonData = encodeURIComponent(JSON.stringify(formdata));
      // navigate(`/pdfincomebill/${jsonData}`);
      window.open(`/pdfincomebill/${jsonData}`);
      GetBillNoData();
      GetIncomeData();
      setnameofpayer("");
      setamount("");
      setmode(null);
      setremarks("");
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function GetBillNoData() {
    try {
      const responseth = await axios.get(apiurl + "expense/");
      settotalcount(responseth.data.length + 1);
      setbillno(
        "E" + (responseth.data.length - 163).toString().padStart(4, "0")
      );
    } catch (error) {
      console.error(error);
    }
  }
  async function GetIncomeData() {
    try {
      const response = await axios.get(apiurl + "expense/");
      setincomedata(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handlemodeChange = (selectedOption) => {
    setmode(selectedOption);
  };
  const handleexpensetypechange = (selectedOption) => {
    setexpensetypeval(selectedOption);
  };
  useEffect(() => {
    updateDate();
    GetBillNoData();
    GetIncomeData();
  }, []);

  //
  const initialData = {
    name: "Base Data Creation",
    link: "/basedata_creation",
    active: "no",
  };
  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Base Data Creation"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>BASE DATA CREATION</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">District</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">School Id</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">School Name</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Student_name</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Gender</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Class</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Father Name</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Address</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Street Name</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Area</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Village</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Pincode</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Phone 1</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Phone 2</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Medium</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Year</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Entry Date</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Source</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Assign To</div>
                      <div className="multisteptablecell2">
                        <Input type="text"  className="textipbill" />
                      </div>
                    </div>




                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain"></div>
        </div>
      </div>
    </Maincomponent>
  );
}
