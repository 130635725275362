import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import * as xlsx from "xlsx";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Enquiry() {
  const [jsonList, setJsonList] = useState([]);

  const apiurl = Apiurl();
  const { user } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [data, setdata] = useState([]);

  const handleSubmit = async () => {
    console.log("submit");
  };
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log(json);
        setdata(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  //
  const initialData = {
    name: "Enquiry",
    link: "/enqyiry",
    active: "no",
  };
  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Enquiry"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>Enquiry</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    {/* <div className="multisteptablerow">
                      <div className="multisteptablecell1">Bill no</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          disabled
                          value={billno}
                          onChange={(e) => {
                            setbillno(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div> */}

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">File</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="file"
                          onChange={readUploadFile}
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">
            {/* <Tablecomp data={incomedata} /> */}
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
