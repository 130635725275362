import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./Auth";
import RequireAuth from "./RequireAuth";
import { useAuth } from "./Auth";

import "./App.sass";
import Loader from "./Components/Loader/Loader";
import Employeeattendance from "./Pages/Employee/Employeeattendance";
import Basedata_upload from "./Pages/Crm/Basedata_upload";
import Enquiry from "./Pages/Crm/Enquiry";
import Prospect from "./Pages/Crm/Prospect";
import Followup from "./Pages/Crm/Followup";
import Basedata_creation from "./Pages/Crm/Basedata_creation";
import Testcreationupdate from "./Pages/Academic/Tesrcreationupdate";
import Timetable from "./Pages/Institute/Timetable";
import Student_existing from "./Pages/Student/Student_existing";
import Course_fee from "./Pages/Institute/Course_fee";
import Course_fee_update from "./Pages/Institute/Course_fee_update";
import Profilebulkupdate from "./Pages/Student/Profilebulkupdate";
import Bankdetails from "./Pages/Finance/Bankdetails";
import Bankdetailsupdate from "./Pages/Finance/Bankdetailsupdate";
import Courseupdate from "./Pages/Institute/Courseupdate";

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"));
const Student_registration = React.lazy(() =>
  import("./Pages/Student/Student_registration")
);
const Student_attendance = React.lazy(() =>
  import("./Pages/Student/Student_attendance")
);
const Student_profile = React.lazy(() =>
  import("./Pages/Student/Student_profile")
);
const Student_reports = React.lazy(() =>
  import("./Pages/Student/Student_reports")
);
const Loginpage = React.lazy(() => import("./Pages/Loginpage/Loginpage"));
const Userdashboard = React.lazy(() =>
  import("./Pages/Dashboard/Userdashboard")
);
const Role = React.lazy(() => import("./Pages/Role/Role"));
const Dummycomp = React.lazy(() => import("./Components/Dummycomp"));
const Billing = React.lazy(() => import("./Pages/Finance/Billing"));
const Directreceipts = React.lazy(() =>
  import("./Pages/Finance/Directreceipt")
);
const Fundtransfer = React.lazy(() => import("./Pages/Finance/Fundtransfer"));
const Branch = React.lazy(() => import("./Pages/Institute/Branch"));
const Course = React.lazy(() => import("./Pages/Institute/Course"));
const Reference = React.lazy(() => import("./Pages/Institute/Reference"));
const Pdffeebill = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfmakerup")
);
const Pdfincomeebill = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfincomemaker")
);
const Studentviewpdfid = React.lazy(() =>
  import("./Components/Pdfprinter/Studentviewpdfid")
);
const Expense = React.lazy(() => import("./Pages/Expense/Expense"));
const Pettycash = React.lazy(() => import("./Pages/Expense/Pettycash"));
const Test_creation = React.lazy(() => import("./Pages/Academic/Testcreation"));
const Markentry = React.lazy(() => import("./Pages/Academic/Markentry"));
const Markupdate = React.lazy(() => import("./Pages/Academic/Markupdate"));
const Exam = React.lazy(() => import("./Pages/Institute/Exam"));
const Examupdate = React.lazy(() => import("./Pages/Institute/Examupdate"));
const Employeecreation = React.lazy(() =>
  import("./Pages/Employee/Employeecreation")
);
const Student_master = React.lazy(() =>
  import("./Pages/Student/Reports/Student_master")
);
const Student_update = React.lazy(() =>
  import("./Pages/Student/Student_update")
);
const Outstanding_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Outstanding_reports")
);
const Studentviewpdfoutstanding = React.lazy(() =>
  import("./Components/Pdfprinter/Pdfoutstandingreport")
);
const Billing_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Billing_reports")
);
const Testwise = React.lazy(() => import("./Pages/Academic/Reports/Testwise"));
const Studentwise = React.lazy(() =>
  import("./Pages/Academic/Reports/Studentwise")
);
const Coursewise = React.lazy(() =>
  import("./Pages/Academic/Reports/Coursewise")
);
const Dr_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Dr_reports")
);
const Ft_reports = React.lazy(() =>
  import("./Pages/Finance/Reports/Ft_reports")
);
const Expense_reports = React.lazy(() =>
  import("./Pages/Expense/Reports/Expense_reports")
);
const Pettycash_reports = React.lazy(() =>
  import("./Pages/Expense/Reports/Pettycash_reports")
);
const Student_attendance_reports = React.lazy(() =>
  import("./Pages/Student/Reports/Student_attendance")
);
const Employeeroles = React.lazy(() =>
  import("./Pages/Employee/Employeeroles")
);
const Employee_profile = React.lazy(() =>
  import("./Pages/Employee/Employeeprofile")
);
const Staffview = React.lazy(() => import("./Pages/Employee/Staffview"));
const Coursewiseupdated = React.lazy(() =>
  import("./Pages/Academic/Reports/Coursewiseupdated")
);
const Testcodes = React.lazy(() => import("./Pages/Testcodes/Testcodes"));
function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Loginpage />} exact />

            <Route
              path="/dashboard/:user"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
              exact
            />
            {/* Student */}

            <Route
              path="/bulk_student_profile/:user"
              element={
                // <RequireAuth>
                <Profilebulkupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_registration/:user"
              element={
                // <RequireAuth>
                <Student_registration />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_update/:id/:user"
              element={
                <RequireAuth>
                  <Student_update />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_existing/:id/:user"
              element={
                // <RequireAuth>
                <Student_existing />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_attendance/:user"
              element={
                <RequireAuth>
                  <Student_attendance />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_profile/:user"
              element={
                <RequireAuth>
                  <Student_profile />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_reports/:user"
              element={
                <RequireAuth>
                  <Student_reports />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/student_master/:user"
              element={
                <RequireAuth>
                  <Student_master />
                </RequireAuth>
              }
              exact
            />

            <Route
              path="/user_dashboard/:user"
              element={
                // <RequireAuth>
                <Userdashboard />
                // </RequireAuth>
              }
              exact
            />

            <Route
              path="/role/:user"
              element={
                <RequireAuth>
                  <Role />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/attendance_reports/:user"
              element={
                // <RequireAuth>
                <Student_attendance_reports />
                // </RequireAuth>
              }
              exact
            />

            {/* Finance */}
            <Route
              path="/billing/:user"
              element={
                <RequireAuth>
                  <Billing />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/bank_details/:user"
              element={
                // <RequireAuth>
                <Bankdetails />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/bank_details_update/:user/:id"
              element={
                // <RequireAuth>
                <Bankdetailsupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/direct_receipt/:user"
              element={
                <RequireAuth>
                  <Directreceipts />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/fund_transfer/:user"
              element={
                <RequireAuth>
                  {" "}
                  <Fundtransfer />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/billing_reports/:user"
              element={
                // <RequireAuth>

                <Billing_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/dr_reports/:user"
              element={
                // <RequireAuth>

                <Dr_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/ft_reports/:user"
              element={
                // <RequireAuth>

                <Ft_reports />
                // </RequireAuth>
              }
              exact
            />
            {/* institute */}
            <Route
              path="/add_branch/:user"
              element={
                <RequireAuth>
                  {" "}
                  <Branch />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/add_course/:user"
              element={
                <RequireAuth>
                  <Course />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_update/:id/:user"
              element={
                // <RequireAuth>
                <Courseupdate />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_fee/:user"
              element={
                <RequireAuth>
                  <Course_fee />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/add_reference/:user"
              element={
                <RequireAuth>
                  <Reference />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/exam/:type/:user"
              element={
                <RequireAuth>
                  <Exam />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/examupdate/:data/:id/:user"
              element={
                <RequireAuth>
                  <Examupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/timetable/:user"
              element={
                // <RequireAuth>
                <Timetable />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/course_fee_update/:user/:id"
              element={
                // <RequireAuth>
                <Course_fee_update />
                // </RequireAuth>
              }
              exact
            />

            {/* Expense */}
            <Route
              path="/expense/:user"
              element={
                <RequireAuth>
                  <Expense />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/petty_cash/:user"
              element={
                <RequireAuth>
                  <Pettycash />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/expense_reports/:user"
              element={
                // <RequireAuth>
                <Expense_reports />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/pc_reports/:user"
              element={
                // <RequireAuth>
                <Pettycash_reports />
                // </RequireAuth>
              }
              exact
            />

            {/* pdf  Printings */}

            <Route
              path="/student_admission_view/:id"
              element={<Studentviewpdfid />}
              exact
            />

            <Route path="/pdffeebill/:data" element={<Pdffeebill />} exact />
            <Route
              path="/pdfincomebill/:data"
              element={<Pdfincomeebill />}
              exact
            />
            {/* Academic */}
            <Route
              path="/test_creation/:user"
              element={
                // <RequireAuth>

                <Test_creation />
                // </RequireAuth>
              }
              exact
            />
            <Route
              path="/test_creation_update/:user/:id"
              element={
                <RequireAuth>
                  {" "}
                  <Testcreationupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/mark_entry/:user"
              element={
                <RequireAuth>
                  <Markentry />{" "}
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/mark_update/:user"
              element={
                <RequireAuth>
                  <Markupdate />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="test_reports/:user"
              element={
                // <RequireAuth>
                <Testwise />
                // </RequireAuth>
              }
            />
            <Route
              path="studentwise_reports/:user"
              element={
                // <RequireAuth>
                <Studentwise />
                // </RequireAuth>
              }
            />
            <Route
              path="coursewise_reports/:user"
              element={
                // <RequireAuth>
                <Coursewiseupdated />
                // </RequireAuth>
              }
            />
            {/* Employee */}
            <Route
              path="/employee_creation/:user"
              element={
                <RequireAuth>
                  <Employeecreation />
                </RequireAuth>
              }
              exact
            />
            <Route
              path="/outstanding_reports/:user"
              element={<Outstanding_reports />}
              exact
            />
            <Route
              path="/employee_attendance/:user"
              element={<Employeeattendance />}
              exact
            />
            <Route
              path="/staff_profile/:user"
              element={<Employee_profile />}
              exact
            />
            <Route path="/staff_view/:Id/:user" element={<Staffview />} exact />
            <Route
              path="outstanding_reports/student_outstanding/:id"
              element={
                // <RequireAuth>
                <Studentviewpdfoutstanding />
                // </RequireAuth>
              }
            />
            <Route
              path="/add_role/:user"
              element={
                // <RequireAuth>
                <Employeeroles />
                // </RequireAuth>
              }
            />
            <Route
              path="/testcodes"
              element={
                // <RequireAuth>
                <Testcodes />
                // </RequireAuth>
              }
            />
            {/* CRM */}
            <Route
              path="/basedata_upload/:user"
              element={
                // <RequireAuth>
                <Basedata_upload />
                // </RequireAuth>
              }
            />
            <Route
              path="/basedata_creation/:user"
              element={
                // <RequireAuth>
                <Basedata_creation />
                // </RequireAuth>
              }
            />
            <Route
              path="/enquiry/:user"
              element={
                // <RequireAuth>
                <Enquiry />
                // </RequireAuth>
              }
            />
            <Route
              path="/prospect/:user"
              element={
                // <RequireAuth>
                <Prospect />
                // </RequireAuth>
              }
            />
            <Route
              path="/followup/:user"
              element={
                // <RequireAuth>
                <Followup />
                // </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </AuthProvider>
  );
}

export default App;
