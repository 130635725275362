import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import Button from "react-bootstrap/Button";
import { Space, Table, Tag } from "antd";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Testcreationupdate() {
  const [jsonList, setJsonList] = useState([]);
  //

  const { user, id } = useParams();

  // const { user } = useParams();
  const navigate = useNavigate();
  const apiurl = Apiurl();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [subject, setsubject] = useState([]);
  const [Assignedtovalues, setAssignedtovalues] = useState([]);
  const [boarddata, setboarddata] = useState([]);
  const [subjectdata, setsubjectdata] = useState([
    { value: "All", label: "All" },
  ]);
  const [chapterdata, setchapterdata] = useState([
    { value: "All", label: "All" },
  ]);
  const [chapterdata2, setchapterdata2] = useState([
    { value: "All", label: "All" },
  ]);
  const [testtypedata, settesttypedata] = useState([]);
  const [coursedata, setcoursedata] = useState([]);
  const [Studentdata, setStudentdata] = useState([
    { value: "All", label: "All" },
  ]);
  const [board, setboard] = useState("");
  const [chapter, setchapter] = useState("");
  const [testtype, settesttype] = useState("");
  const [course, setcourse] = useState("");
  const [date, setdate] = useState("");
  const [Assignedto, setAssignedto] = useState("");
  const [Duration, setDuration] = useState("");
  const [Totalmarks, setTotalmarks] = useState("");
  const [Testid, setTestid] = useState("");
  const [idno, sertidno] = useState("");
  const [pending, setpending] = useState("");

  async function postData(event) {
    event.preventDefault();
    if (
      (board != "") &
      (testtype != "") &
      (Totalmarks != "") &
      (subject != "")
    ) {
      try {
        const chapterdataString = selectedValues
          .map((item) => item.value)
          .join(", ");
        const subjectdatastring = subject.map((item) => item.value).join(", ");
        const assignedtodatastring = Assignedtovalues.map(
          (item) => item.value
        ).join(", ");
        const formData = {
          board: board,
          subject: subjectdatastring,
          chapter: chapterdataString == "" ? "All" : chapterdataString,
          testtype: testtype,
          date: date,
          status: pending,
          course: course,
          createdby: user,
          assignedto: assignedtodatastring,
          duration: Duration,
          totalmarks: Totalmarks,
          testid: Testid,
        };
        const response = await axios.put(apiurl + `test/${id}/`, formData);
        console.log(formData);
        alert("Updated Sucessfully");
        navigate(`/test_creation/${user}`);
        // setboard("");
        // setchapter("");
        // setdate("");
        // setsubject([]);
        // setcourse("");
        // settesttype("");
        // setAssignedto("");
        // setTotalmarks("");
        // setDuration("");
        // setTestid("");
        // setAssignedtovalues([]);

        // setchapterdata([{ value: "All", label: "All" }]);
        // setSelectedValues([]);
        // setAssignedtovalues([{ value: "All", label: "All" }]);
        fetchDatas();
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Please Enter All Fields..");
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  async function fetchData(coursen) {
    try {
      const response = await axios.get(
        apiurl + `test/?testid=&course=${coursen}&status=`
      );
      // setData2(response.data);
      sertidno(response.data.length + 1);
      console.warn(response.data);
      console.warn(response.data.length);
      var no = response.data.length + 1;
      setTestid(coursen + "" + no.toString().padStart(4, "0"));
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchDatas() {
    try {
      const response = await axios.get(apiurl + `test/`);
      setData2(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  async function fetchboarddata() {
    try {
      const response = await axios.get(apiurl + "board/");
      setboarddata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchsubject(board) {
    try {
      const response = await axios.get(apiurl + `subject/?board=${board}`);
      const convertedArray = response.data.map((item) => ({
        value: item.subject,
        label: item.subject,
      }));
      // console.warn(convertedArray);
      setsubjectdata([...subjectdata, ...convertedArray]);
      // console.warn(subjectdata);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchchapter1() {
    try {
      setchapterdata([{ value: "All", label: "All" }]);
      const response = await axios.get(apiurl + `chapter/`);
      const uniqueChapters = new Set();
      const valuesArray = subject.map((item) => item.value);
      const filteredChapters = response.data.filter((chapter) =>
        valuesArray.includes(chapter.subject.toUpperCase())
      );
      filteredChapters.forEach((chapter) => {
        if (valuesArray.includes(chapter.subject.toUpperCase())) {
          uniqueChapters.add(chapter.chapter + "-" + chapter.desc);
        }
      });
      const convertedArray = Array.from(uniqueChapters).map((chapter) => ({
        value: chapter,
        label: chapter,
      }));
      console.log(convertedArray);
      setchapterdata([...chapterdata, ...convertedArray]);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchtesttype() {
    try {
      const response = await axios.get(apiurl + `testtype/`);
      settesttypedata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchcoursedata() {
    try {
      const response = await axios.get(apiurl + `course/`);
      setcoursedata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchStudentdata(course) {
    try {
      const emptyObject = { value: "All", label: "All" };
      const response = await axios.get(
        apiurl +
          `studentreg/?student_id=&batch=&branch=&course=${course}&status=Active&std_name=`
      );
      const convertedArray = response.data.map((item) => ({
        value: item.student_id,
        label: item.std_name + "-" + item.student_id,
      }));
      const newArray = [emptyObject].concat(convertedArray);
      setStudentdata(newArray);
    } catch (error) {
      alert("Network error");
    }
  }
  const options = [{ value: "Test", label: "Test" }];
  const emptyoptions = [
    // { value: "Test", label: "Test" },
  ];

  async function fetchdataid() {
    try {
      const response = await axios.get(apiurl + `test/${id}/`);
      //   setboarddata(response.data);
      setTestid(response.data.testid);
      setTotalmarks(response.data.totalmarks);
      setDuration(response.data.duration);
      setdate(response.data.date);
      setcourse(response.data.course);
      setboard(response.data.board);
      settesttype(response.data.testtype);
      setpending(response.data.status);
      console.log(response.data.subject);
      // setsubject(response.data.subject);

      // setStudentdata([{ value: "All", label: "All" }]);
      // setcourse(response.data.course);
      // fetchData(response.data.course);
      // fetchStudentdata(response.data.course);
      fetchsubject(response.data.board);
      fetchStudentdata(response.data.course);
      const chapterDataArray = response.data.chapter
        .split(", ")
        .map((value) => ({
          value: value,
          label: value,
        }));
      console.error(chapterDataArray);
      setSelectedValues(chapterDataArray);
      const subjectdataarray = response.data.subject
        .split(", ")
        .map((value) => ({
          value: value,
          label: value,
        }));
      setsubject(subjectdataarray);

      // Reversing subjectdatastring
      const subjectDataArray = response.data.subject
        .split(", ")
        .map((value) => ({
          value: value == " " ? "All" : value,
          label: value == " " ? "All" : value,
        }));
      // setsubject(subjectDataArray);
      console.log(subjectDataArray);
      // Reversing assignedtodatastring
      const assignedToDataArray = response.data.assignedto
        .split(", ")
        .map((value) => ({
          value: value,
          label: value,
        }));
      setAssignedtovalues(assignedToDataArray);
    } catch (error) {
      alert("Network error");
    }
  }

  useEffect(() => {
    // fetchData();
    fetchDatas();
    fetchboarddata();
    fetchtesttype();
    fetchcoursedata();
    fetchdataid();
    // fetchchapter1()
  }, []);

  useEffect(() => {
    for (let h = 0; h < subject.length; h++) {
      fetchchapter1();
    }
  }, [subject.length]);

  //
  const initialData = {
    name: "Test Creation",
    link: "/test_creation",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Test Creation"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>TEST CREATION UPDATE </b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Exam/class</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select mb-0"
                          id="floatingSelect"
                          size="sm"
                          style={{ height: "35px", "font-size": "12px" }}
                          aria-label="Floating label select example"
                          name="branch"
                          required
                          disabled
                          value={board}
                          onChange={(e) => {
                            setboard(e.target.value);
                            fetchsubject(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {boarddata.map((option) => (
                            <option value={option.board} key={option.id}>
                              {option.board}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Course</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select mb-0"
                          id="floatingSelect"
                          size="sm"
                          style={{ height: "35px", "font-size": "12px" }}
                          aria-label="Floating label select example"
                          name="branch"
                          required
                          value={course}
                          disabled
                          onChange={(e) => {
                            setStudentdata([{ value: "All", label: "All" }]);
                            setcourse(e.target.value);
                            fetchData(e.target.value);
                            fetchStudentdata(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {coursedata.map((option) => (
                            <option value={option.course_name} key={option.id}>
                              {option.course_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Subject</div>
                      <div className="multisteptablecell2">
                        <Select
                          isMulti
                          name="Subject"
                          isClearable
                          isSearchable
                          isDisabled={course == "" ? true : false}
                          options={
                            subject.some((item) => item.label == "All")
                              ? emptyoptions
                              : subjectdata
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={subject}
                          onChange={(selectedOptions) => {
                            setsubject(selectedOptions);
                            console.log(subject);
                            setSelectedValues([]);
                            fetchchapter1();
                            if (subject == "") {
                              setchapterdata([{ value: "", label: "All" }]);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Chapter</div>
                      <div className="multisteptablecell2">
                        <Select
                          isMulti
                          name="course"
                          isClearable
                          isSearchable
                          isDisabled={subject == "" ? true : false}
                          options={
                            selectedValues.some((item) => item.label == "All")
                              ? emptyoptions
                              : chapterdata
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={selectedValues}
                          onChange={(selectedOptions) => {
                            setSelectedValues(selectedOptions);
                            console.log("Selected Values:", selectedOptions);
                          }}
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Test Type</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select mb-0"
                          id="floatingSelect"
                          size="sm"
                          style={{ height: "35px", "font-size": "12px" }}
                          disabled={selectedValues == "" ? true : false}
                          required
                          value={testtype}
                          onChange={(e) => {
                            settesttype(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {testtypedata.map((option) => (
                            <option value={option.testtype} key={option.id}>
                              {option.testtype}
                            </option>
                          ))}
                        </select>{" "}
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Date</div>
                      <div className="multisteptablecell2">
                        <Input
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={date}
                          min="2023-01-01"
                          type="Date"
                          required
                          // disabled
                          // disabled
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Assigned to</div>
                      <div className="multisteptablecell2">
                        <Select
                          isMulti
                          name="Assigned To"
                          isClearable
                          isSearchable
                          isDisabled={course == "" ? true : false}
                          options={
                            Assignedtovalues.some((item) => item.label == "All")
                              ? emptyoptions
                              : Studentdata
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          value={Assignedtovalues}
                          onChange={(selectedOptions) => {
                            setAssignedtovalues(selectedOptions);
                            console.log("Selected Values:", selectedOptions);
                          }}
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Total Marks</div>
                      <div className="multisteptablecell2">
                        <Input
                          onChange={(e) => {
                            setTotalmarks(e.target.value);
                          }}
                          value={Totalmarks}
                          type="number"
                          name="quantity"
                          min="0"
                          max="720"
                          // disabled
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Duration</div>
                      <div className="multisteptablecell2">
                        <Input
                          onChange={(e) => {
                            setDuration(e.target.value);
                          }}
                          type="text"
                          // disabled
                          value={Duration}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Test Id</div>
                      <div className="multisteptablecell2">
                        <Input
                          value={Testid}
                          type="text"
                          onChange={(e) => {
                            setTestid(e.target.value);
                          }}
                          disabled
                          //   disabled
                          className="textipbill"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Update
                </button>
              </div>
            </div>
          </div>

          <div className="Billcompmain"></div>
        </div>
      </div>
    </Maincomponent>
  );
}
