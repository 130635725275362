import React, { useState, useEffect } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Apiurl from "../../Apiurl";
import { Select } from "antd";
import { Input } from "antd";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Courseupdate() {
  const [jsonList, setJsonList] = useState([]);
  //
  const { user, id } = useParams();
  const navigate = useNavigate();
  const apiurl = Apiurl();
  const [formData, setFormData] = useState({});
  const [data2, setData2] = useState([]);
  const [data, setdata] = useState({
    fields: [
      {
        name: "course_name",
        label: "Name",
        type: "inputtxt",
        isdisabled: true,
      },
      {
        name: "description",
        label: "Description ",
        type: "inputtxt",
      },
      {
        name: "status",
        label: "Status ",
        type: "inputdrop",
        options: [
          {
            label: "Active",
            value: "Active",
          },
          {
            label: "In-Active",
            value: "In-Active",
          },
        ],
      },
    ],
  });

  async function postData(event) {
    event.preventDefault();
    try {
      const response = await axios.put(apiurl + `course/${id}/`, formData);
      fetchData();
      alert("Updated Sucessfully");
      setFormData({});
      navigate(`/add_course/${user}`);
    } catch (error) {
      console.error(error);
      // Handle error here
    }
  }

  async function fetchData() {
    try {
      const response = await axios.get(apiurl + `course/${id}/`);
      console.log(response.data);
      setFormData(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  //
  const initialData = {
    name: "Course",
    link: "/add_course",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Course"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>COURSE</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    {data.fields.map((item, index) => (
                      <div className="multisteptablerow" key={index}>
                        <div className="multisteptablecell1">{item.label}</div>
                        <div className="multisteptablecell2">
                          {item.type === "inputtxt" ? (
                            <Input
                              type="text"
                              name={item.name}
                              value={formData[item.name]}
                              disabled={item.isdisabled}
                              onChange={(e) =>
                                handleChange(item.name, e.target.value)
                              }
                            />
                          ) : (
                            <Select
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder={`Select ${item.label}`}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? "").includes(input)
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? "").toLowerCase()
                                  )
                              }
                              value={formData[item.name]}
                              onChange={(value) =>
                                handleChange(item.name, value)
                              }
                              options={item.options}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={postData}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain"></div>
        </div>
      </div>
    </Maincomponent>
  );
}
