import React from "react";
import { Menu, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

export default function Sidebuttondropdown(props) {
  const navigate = useNavigate();

  const dropitems = props.dataitem;

  const convertedArray = dropitems
    .filter((item) => item.isvisible) // Filter out items with isvisible set to false
    .map((item, index) => {
      if (item.isdropdown) {
        // If it's a dropdown item
        return (
          <Menu.SubMenu
            title={item.name}
            key={index + 21}
            className="menuItemCustomStyle"
          >
            {item.reportitems.map((repitem, indx) => (
              <Menu.Item
                key={indx + 31 + index}
                onClick={() => {
                  navigate(`${repitem.url}/${props.user}`);
                }}
              >
                {repitem.name}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        );
      } else {
        // If it's a regular item
        return (
          <Menu.Item
            key={index + 11}
            onClick={() => {
              navigate(`${item.url}/${props.user}`);
            }}
          >
            {item.name}
          </Menu.Item>
        );
      }
    });

  return (
    <Dropdown overlay={<Menu>{convertedArray}</Menu>} arrow trigger={["click"]}>
      <div
        className="main-sidebutton"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div className="sidebutton-icon">
          <i className={props.icon}></i>
        </div>
        <div className="sidebutton-text">{props.name}</div>
      </div>
    </Dropdown>
  );
}
