import React from "react";
import { useNavigate } from "react-router-dom";

export default function Sidebutton(props) {
  const navigate = useNavigate();
  return (
    <div
      className="main-sidebutton"
      onClick={() => {
        navigate(`/dashboard/${props.user}`);
      }}
    >
      <div className="sidebutton-icon">{props.icon}</div>
      <div className="sidebutton-text">{props.name}</div>
    </div>
  );
}
