import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Apiurl from "../Apiurl";

export default function Subjectoptions() {
  const [StudentData, setStudentData] = useState([]);
  const apiurl = Apiurl();

  async function fetchStudentData() {
    try {
      const response = await axios.get(apiurl + "subject/");

      setStudentData(response.data);
      // Handle the response data here
    } catch (error) {
      console.error(error);
      // Handle error here
      console.log("network error");
    }
  }
  const options = StudentData.map((student, index) => ({
    value:  student.board + " - " + student.subject,
    label: student.board + " - " + student.subject,
  }));
  console.log(options);

  useEffect(() => {
    fetchStudentData();
  }, []);

  return options;
}
