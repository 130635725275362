import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import Courseoptions from "../../Options/Courseoptions";
import Form from "react-bootstrap/Form";
import { Tabhelper } from "../../Helper/Tabhelper";
import { SyncLoader } from "react-spinners";

export default function Profilebulkupdate() {
  const [jsonList, setJsonList] = useState([]);

  const initialData = {
    name: "Bulk Update",
    link: "/bulk_student_profile",
    active: "no",
  };
  const apiurl = Apiurl();
  const navigate = useNavigate();
  const options = Courseoptions();
  const { user } = useParams();

  const [loader, setloader] = useState(false);
  const [sname, setsname] = useState(null);

  const [batch, setbatch] = useState(null);
  const [reason, setreason] = useState("null");
  const [Studentdata, setStudentdata] = useState([]);

  async function GetStudData() {
    if (sname != null) {
      try {
        console.log(sname);
        const response = await axios.get(
          apiurl +
            `studentreg/?student_id=&batch=${batch.value}&branch=&course=${sname.value}&status=Active&std_name=`
        );
        console.log(response.data);
        setStudentdata(response.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Please Select Course.");
    }
  }
  async function submitdata() {
    try {
      const formdata = Studentdata.map((item, index) => ({
        id: item.id,
        firstname: item.firstname,
        lastname: item.lastname,
        email: item.email,
        gender: item.gender,
        father_name: item.father_name,
        father_occupation: item.father_occupation,
        mother_name: item.mother_name,
        mother_occupation: item.mother_occupation,
        nationality: item.nationality,
        category: item.category,
        district: item.district,
        contact1: item.contact1,
        contact2: item.contact2,
        date_of_birth: item.date_of_birth,
        school_name: item.school_name,
        address: item.address,
        pincode: item.pincode,
        state: item.state,
        board: item.board,
        group: item.group,
        mark_10: item.mark_10,
        mark_11: item.mark_11,
        mark_12: item.mark_12,
        course: item.course,
        batch: item.batch,
        duration: item.duration,
        total_fee: item.total_fee,
        join_date: item.join_date,
        end_date: item.end_date,
        branch: item.branch,
        scholorship: item.scholorship,
        scholorship_percentage: item.scholorship_percentage,
        source: item.source,
        about_studibreeze: item.about_studibreeze,
        student_id: item.student_id,
        school_state: item.school_state,
        school_district: item.school_district,
        studend_image: item.studend_image,
        status: "Completed",
        standard: item.standard,
        sno: item.sno,
        religion: item.religion,
        school_address: item.school_address,
        school_pincode: item.school_pincode,
        reference: item.reference,
        after_scholorship: item.after_scholorship,
        scholorship_amount: item.scholorship_amount,
        fee_paid: item.fee_paid,
        remainig_fee: item.remainig_fee,
        std_name: item.std_name,
      }));

      setloader(true);
      const response = await axios.put(apiurl + `bulkstudentupdate/`, formdata);
      console.log(response.data);
      alert("Updated !");

      setloader(false);
    } catch (error) {
      console.error(error);
      setloader(false);
    }
  }
  const handleStudentChange = (selectedOption) => {
    setsname(selectedOption);
  };

  const handlebatchchange = (selectedOption) => {
    setbatch(selectedOption);
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Bulk Update"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>STUDENT BULK UPDATE</b>
          </div>
          <br />

          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Course</div>
                      <div className="multisteptablecell2">
                        <Select
                          value={sname}
                          onChange={handleStudentChange}
                          options={options}
                          placeholder="Select Course"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Batch</div>
                      <div className="multisteptablecell2">
                        <Select
                          value={batch}
                          onChange={handlebatchchange}
                          options={[
                            {
                              value: "23",
                              label: "23",
                            },
                            {
                              value: "24",
                              label: "24",
                            },
                            {
                              value: "25",
                              label: "25",
                            },
                          ]}
                          placeholder="Select Batch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={GetStudData}>
                  Find
                </button>
              </div>
            </div>
          </div>
          <br />
          {Studentdata != "" ? (
            <div>
              <div>
                <div>
                  <div style={{ padding: "1%" }}>
                    <div className="table-responsive">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Sno</th>
                            <th scope="col"> Name </th>

                            <th scope="col"> Student Id</th>
                            <th scope="col"> Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Studentdata.map((item, index) => (
                            <>
                              <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td>{item.std_name}</td>
                                <td>{item.student_id}</td>
                                <td>{item.status}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <Button
                      disabled={loader}
                      onClick={submitdata}
                      variant="primary"
                    >
                      {loader == false ? (
                        "  Update "
                      ) : (
                        <SyncLoader color="white" size={8} />
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Maincomponent>
  );
}
