import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Timetable() {
  const [jsonList, setJsonList] = useState([]);
  //
  const { TextArea } = Input;
  const apiurl = Apiurl();
  const { user } = useParams();
  const navigate = useNavigate();

  const [title, settitle] = useState("");
  const [course, setcourse] = useState("");
  const [description, setdescription] = useState("");

  const [date, setdate] = useState("");
  const [coursedata, setcoursedata] = useState([]);
  const [materialdata, setmaterialdata] = useState([]);
  const [typeofdata, settypeofdata] = useState("");
  const typeoptions = [
    { value: "Holiday", label: "Holiday" },
    { value: "Others", label: "Others" },
    // { value: "Class", label: "Class" },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = {
      title: title,
      course: course,
      description: description,
      date: date,
      type_of_data: typeofdata,
    };
    try {
      const response = await axios.post(apiurl + "timetable/", formdata);
      console.log(formdata);
      alert("Added");

      settitle("");
      fetchmaterialData();
      setdescription("");
      setcourse("");
    } catch (error) {
      console.error(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function fetchCourseData() {
    try {
      const response = await axios.get(apiurl + "course/");
      setcoursedata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }
  async function fetchmaterialData() {
    try {
      const response = await axios.get(apiurl + "timetable/");
      setmaterialdata(response.data);
    } catch (error) {
      alert("Network error");
    }
  }

  async function deleteData(id) {
    if (window.confirm("Are you sure you want to Delete this?")) {
      try {
        const response = await axios.delete(apiurl + "timetable/" + id + "/");
        console.log(response.data);
        fetchmaterialData();
        alert("Deleted Sucessfully !");
        // Handle the response data here
      } catch (error) {
        console.error(error);
        // Handle error here
      }
    }
  }

  useEffect(() => {
    fetchCourseData();
    fetchmaterialData();
  }, []);

  //
  const initialData = {
    name: "Timetable",
    link: "/timetable",
    active: "no",
  };
  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);
      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td> {item.title} </td>
              <td> {item.course} </td>
              <td> {item.date} </td>
              <td> {item.description} </td>
              <td>
                {" "}
                <Button
                  onClick={() => {
                    deleteData(item.id);
                  }}
                  variant="danger"
                  size="sm"
                  //   className="btn btn-danger m-2"
                >
                  <i className="fa fa-trash"></i>
                </Button>
                <Button
                  variant="warning"
                  size="sm"
                  //   className="btn btn-warning m-2"
                  onClick={() => {
                    navigate(`/examupdate/timetable/${item.id}/${user}`);
                  }}
                >
                  <i className="fa fa-edit"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };

    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th scope="col">Sno</th>
              <th scope="col"> Title</th>
              <th scope="col"> Course</th>
              <th scope="col"> Date</th>
              <th scope="col">Description</th>
              <th scope="col"> Actions </th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  return (
    <Maincomponent tabname={"Timetable"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>TIME TABLE</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              {" "}
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Title</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={title}
                          onChange={(e) => {
                            settitle(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1"> Course</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select"
                          aria-label="Floating label select example"
                          value={course}
                          required
                          onChange={(e) => {
                            setcourse(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>

                          {coursedata.map((option) => (
                            <option value={option.course_name} key={option.id}>
                              {option.course_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Type</div>
                      <div className="multisteptablecell2">
                        <select
                          className="form-select"
                          aria-label="Floating label select example"
                          value={typeofdata}
                          required
                          onChange={(e) => {
                            settypeofdata(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>

                          {typeoptions.map((option) => (
                            <option value={option.value} key={option.id}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">date</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="date"
                          onChange={(e) => {
                            setdate(e.target.value);
                          }}
                          value={date}
                          className="textipbill"
                        />
                      </div>
                    </div>

                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Description</div>
                      <div className="multisteptablecell2">
                        {/* <Input
                          type="text"
                          onChange={(e) => {
                            setdescription(e.target.value);
                          }}
                          value={description}
                          className="textipbill"
                        /> */}
                        <TextArea
                          onChange={(e) => {
                            setdescription(e.target.value);
                          }}
                          value={description}
                          className="textipbill"
                          placeholder="Description"
                          autosize={{ minRows: 2, maxRows: 6 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">
            <Tablecomp data={materialdata} />
          </div>
        </div>
      </div>
    </Maincomponent>
  );
}
