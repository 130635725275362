import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import { Modal } from "antd";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Studentoptions from "../../Options/Studentoptions";

import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Bankdetails() {
  const apiurl = Apiurl();
  // const options = Optionsdropdown();
  const { user } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);

  const [bankid, set_bankid] = useState("");
  const [accname, setaccname] = useState("");
  const [ifsc, setifsc] = useState("");
  const [branch, setbranch] = useState("");
  const [open1, setopen1] = useState(false);
  const [bankiddata, setbankiddata] = useState([
    {
      id: "",
      accnumber: "",
      ifsc: "",
      branch: "",
    },
  ]);
  const handleFormChange = (index, event) => {
    let data = [...bankiddata];
    data[index][event.target.name] = event.target.value;
    setbankiddata(data);
  };
  const removeFields = (index) => {
    let data = [...bankiddata];
    data.splice(index, 1);
    setbankiddata(data);
  };
  const addFields = () => {
    let newfield = {
      id: "",
      accnumber: "",
      ifsc: "",
      branch: "",
    };

    setbankiddata([...bankiddata, newfield]);
  };

  const handleSubmit = async () => {
    const converteddata = bankiddata.map((item, index) => ({
      bankid: item.id,
      accname: accname,
      ifsc: item.ifsc,
      branch: item.branch,
      accnumber: item.accnumber,
    }));
    console.log(converteddata);
    try {
      const response = await axios.post(
        apiurl + "bulkbankdetailscreation/",
        converteddata
      );
      alert("Bank Details Added");
      Getbankdetails();
      set_bankid("");
      setaccname("");
      setifsc("");
      setbranch("");
    } catch (error) {
      console.log(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function Getbankdetails() {
    try {
      const response = await axios.get(apiurl + "bankdetails/");
      setdata(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    Getbankdetails();
  }, []);
  async function deleteData(id) {
    if (window.confirm("Are you sure you want to Delete this?")) {
      try {
        const response = await axios.delete(apiurl + "bankdetails/" + id + "/");
        console.log(response.data);
        Getbankdetails();
        alert("Deleted Sucessfully !");
        // Handle the response data here
      } catch (error) {
        console.error(error);
        // Handle error here
      }
    }
  }
  //
  const Tablecomp = (props) => {
    const [data, setData] = useState(props.data.slice(0).reverse());

    const [postsPerPage, setPostsPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(1);
    const showData = () => {
      const indexOfLastPage = currentPage * postsPerPage;
      const indexOfFirstPage = indexOfLastPage - postsPerPage;
      const currentPosts = data.slice(indexOfFirstPage, indexOfLastPage);

      try {
        return currentPosts.map((item, index) => (
          <tbody key={index}>
            <tr>
              <td>{postsPerPage * (currentPage - 1) + index + 1}</td>
              <td>{item.bankid}</td>
              <td>{item.accname}</td>
              <td>{item.accnumber}</td>
              <td>{item.ifsc}</td>
              <td>{item.branch}</td>
              <td>
                <Button
                  onClick={() => {
                    navigate(`/bank_details_update/${user}/${item.id}`);
                  }}
                  size="sm"
                  variant="outline-warning"
                >
                  <i className="fa fa-edit"></i>
                </Button>{" "}
                <Button
                  onClick={() => {
                    deleteData(item.id);
                  }}
                  size="sm"
                  variant="outline-danger"
                >
                  <i className="fa fa-trash"></i>
                </Button>{" "}
              </td>
            </tr>
          </tbody>
        ));
      } catch (e) {
        alert(e.message);
      }
    };

    const showPagination = () => {
      const pageNumbers = [];
      const totalPosts = data.length;

      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.slice(
        Math.max(0, currentPage - 2),
        Math.min(currentPage + 1, pageNumbers.length)
      );

      return (
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<<"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="page-link"
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
            </li>
            {renderPageNumbers.map((number) => (
              <li
                key={number}
                className={
                  currentPage === number ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={() => setCurrentPage(number)}
                  className="page-link"
                >
                  {number}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">"}
              </button>
            </li>
            <li className="page-item">
              <button
                onClick={() =>
                  setCurrentPage(Math.ceil(totalPosts / postsPerPage))
                }
                className="page-link"
                disabled={currentPage === Math.ceil(totalPosts / postsPerPage)}
              >
                {">>"}
              </button>
            </li>
          </ul>
        </nav>
      );
    };
    return (
      <div className="container">
        <table className="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              {/* Sno	Bill no	Date	Amount Paid	Mode	Bill Status	Actions */}
              <th>Sno</th>
              <th>Bank Id</th>
              <th>Acc Name</th>
              <th>Acc Number</th>
              <th>IFSC</th>
              <th>Branch</th>

              <th>Actions</th>
            </tr>
          </thead>
          {showData()}
        </table>

        <div style={{ float: "right" }}>{showPagination()}</div>
      </div>
    );
  };
  const [jsonList, setJsonList] = useState([]);
  //
  const initialData = {
    name: "Bank Details",
    link: "/bank_details",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Bank Details"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>BANK DETAILS</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Acc Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          value={accname}
                          onChange={(e) => {
                            setaccname(e.target.value);
                          }}
                          className="textipbill"
                        />
                      </div>
                    </div>
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Bank Id</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          disabled
                          value={
                            bankiddata.length == 0
                              ? "Add Bank Id."
                              : bankiddata.length
                          }
                          onChange={(e) => {
                            set_bankid(e.target.value);
                          }}
                          className="textipbill"
                          style={{ width: "80%" }}
                        />
                        &nbsp; &nbsp;
                        <Button
                          size="sm"
                          variant="warning"
                          onClick={() => {
                            setopen1(true);
                          }}
                        >
                          +
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain">
            <Tablecomp data={data} />
          </div>
        </div>
      </div>
      <Modal
        // title="Group"
        // centered
        open={open1}
        styles={{
          content: { backgroundColor: "white" }, // turns the Modal red
        }}
        onOk={() => setopen1(false)}
        onCancel={() => setopen1(false)}
        width={1200}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <Button size="sm" onClick={addFields}>
              Add
            </Button>
            &nbsp;&nbsp;
            {/* <CancelBtn /> */}
            <OkBtn size="sm" />
          </>
        )}
      >
        <h5>Bank Id</h5>
        <hr></hr>
        <table style={{ width: "100%" }}>
          <tr style={{ padding: 2 }}>
            {/* <th> Sno</th> */}
            <th>Id</th>
            <th>Acc Number</th>
            <th>Branch</th>
            <th>IFSC</th>

            <th>Actions</th>

            <th></th>
          </tr>

          {bankiddata.map((input, index) => {
            return (
              <>
                <tr>
                  {/* <th>{index + 1}</th> */}
                  <th>
                    {" "}
                    <Input
                      name="id"
                      className="textipbill"
                      style={{
                        width: "90%",
                        border: "0.5px solid gray",
                        color: "black",
                      }}
                      placeholder="Bank Id"
                      onChange={(event) => handleFormChange(index, event)}
                      value={input.id}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input
                      name="accnumber"
                      className="textipbill"
                      style={{
                        width: "90%",
                        border: "0.5px solid gray",
                        color: "black",
                      }}
                      placeholder="Account Number"
                      onChange={(event) => handleFormChange(index, event)}
                      value={input.accnumber}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input
                      name="branch"
                      className="textipbill"
                      style={{
                        width: "90%",
                        border: "0.5px solid gray",
                        color: "black",
                      }}
                      placeholder="Branch"
                      onChange={(event) => handleFormChange(index, event)}
                      value={input.branch}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input
                      name="ifsc"
                      className="textipbill"
                      style={{
                        width: "90%",
                        border: "0.5px solid gray",
                        color: "black",
                      }}
                      placeholder="IFSC"
                      onChange={(event) => handleFormChange(index, event)}
                      value={input.ifsc}
                    />
                  </th>

                  <th>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => removeFields(index)}
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </Button>
                  </th>
                </tr>
              </>
            );
          })}
        </table>
        <br />
      </Modal>
    </Maincomponent>
  );
}
