import React, { useState, useEffect } from "react";
import axios from "axios";
import Apiurl from "../Apiurl";

export default function Courseoptions() {
  const apiurl = Apiurl();
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiurl}course/`);

      const convertedarray = response.data.map((item, index) => ({
        value: item.course_name,
        label: item.course_name,
      }));
      setdata(convertedarray);
      console.log(convertedarray);
    } catch (error) {
      console.warn("Error fetching data:", error);
      alert(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return data;
}
