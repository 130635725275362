import React, { useEffect, useState } from "react";
import Maincomponent from "../../Components/Maincomponent/Maincomponent";
import { Input } from "antd";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import { Modal } from "antd";
import Select from "react-select";
// import { Select } from "antd";
import { Space, Table, Tag } from "antd";
import axios from "axios";
import Apiurl from "../../Apiurl";
import Studentoptions from "../../Options/Studentoptions";

import Button from "react-bootstrap/Button";
import { Tabhelper } from "../../Helper/Tabhelper";

export default function Bankdetailsupdate() {
  const apiurl = Apiurl();
  // const options = Optionsdropdown();
  const { user, id } = useParams();
  const navigate = useNavigate();
  const [data, setdata] = useState([]);

  const [bankid, set_bankid] = useState("");
  const [accname, setaccname] = useState("");
  const [ifsc, setifsc] = useState("");
  const [branch, setbranch] = useState("");
  const [accnumber, setaccnumber] = useState("");
  const [bankiddata, setbankiddata] = useState([
    {
      id: "",
      accnumber: "",
      ifsc: "",
      branch: "",
    },
  ]);
  const handleFormChange = (index, event) => {
    let data = [...bankiddata];
    data[index][event.target.name] = event.target.value;
    setbankiddata(data);
  };
  const removeFields = (index) => {
    let data = [...bankiddata];
    data.splice(index, 1);
    setbankiddata(data);
  };
  const addFields = () => {
    let newfield = {
      id: "",
      accnumber: "",
      ifsc: "",
      branch: "",
    };

    setbankiddata([...bankiddata, newfield]);
  };

  const handleSubmit = async () => {
    const converteddata = {
      accname: accname,
      accnumber: accnumber,
      bankid: bankid,
      branch: branch,
      ifsc: ifsc,
    };
    console.log(converteddata);
    try {
      const response = await axios.put(
        apiurl + "bankdetails/" + id + "/",
        converteddata
      );
      alert("Bank Details Added");
      navigate(`/dashboard/${user}`);
    } catch (error) {
      console.log(error);
      alert("Some Error occured\nTry again Later");
    }
  };
  async function Getbankdetails() {
    console.log(id);
    try {
      const response = await axios.get(apiurl + "bankdetails/" + id + "/");
      setdata(response.data);
      console.log(response.data);
      set_bankid(response.data.bankid);
      setaccname(response.data.accname);
      setaccnumber(response.data.accnumber);
      setbranch(response.data.branch);
      setifsc(response.data.ifsc);
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    Getbankdetails();
  }, []);

  const [jsonList, setJsonList] = useState([]);
  //
  const initialData = {
    name: "Bank Details",
    link: "/bank_details",
    active: "no",
  };

  // useEffect to add initial data to the JSON list
  useEffect(() => {
    Tabhelper(initialData, setJsonList);
  }, []);

  return (
    <Maincomponent tabname={"Bank Details"}>
      <div className="component-sub">
        <div className="component-sub1">
          <div className="multistephead">
            <b>BANK DETAILS UPDATE</b>
          </div>
          <br />
          <div className="Billcompmain">
            {/* <div className="Billcompmain1"></div> */}
            <div className="Billcompmain2">
              <div className="multistepcontainer1">
                <div className="multistepsubcontainer1">
                  <div className="multisteptable">
                    {/*  */}
                    <div className="multisteptablerow">
                      <div className="multisteptablecell1">Acc Name</div>
                      <div className="multisteptablecell2">
                        <Input
                          type="text"
                          disabled
                          value={accname}
                          onChange={(e) => {
                            setaccname(e.target.value);
                          }}
                          className="textipbill"
                          placeholder="Account Name"
                        />
                      </div>
                    </div>

                    {bankiddata.map((input, index) => {
                      return (
                        <>
                          <div className="multisteptablerow">
                            <div className="multisteptablecell1">Bank Id</div>
                            <div className="multisteptablecell2">
                              <Input
                                name="id"
                                className="textipbill"
                                placeholder="Bank Id"
                                // disabled
                                onChange={(event) =>
                                  set_bankid(event.target.value)
                                }
                                value={bankid}
                              />
                            </div>
                          </div>
                          <div className="multisteptablerow">
                            <div className="multisteptablecell1">
                              Acc Number
                            </div>
                            <div className="multisteptablecell2">
                              <Input
                                name="accnumber"
                                className="textipbill"
                                placeholder="Account Number"
                                onChange={(e) => {
                                  setaccnumber(e.target.value);
                                }}
                                value={accnumber}
                              />
                            </div>
                          </div>{" "}
                          <div className="multisteptablerow">
                            <div className="multisteptablecell1">Branch</div>
                            <div className="multisteptablecell2">
                              <Input
                                name="branch"
                                className="textipbill"
                                placeholder="Branch"
                                onChange={(e) => {
                                  setbranch(e.target.value);
                                }}
                                value={branch}
                              />
                            </div>
                          </div>{" "}
                          <div className="multisteptablerow">
                            <div className="multisteptablecell1">IFSC </div>
                            <div className="multisteptablecell2">
                              <Input
                                name="ifsc"
                                className="textipbill"
                                placeholder="IFSC"
                                onChange={(e) => {
                                  setifsc(e.target.value);
                                }}
                                value={ifsc}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="multistepcontainerbtn">
                <button className="buttoncompnent1" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="Billcompmain"></div>
        </div>
      </div>
    </Maincomponent>
  );
}
